//#region imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Person2Icon from "@mui/icons-material/Person2";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import BusinessIcon from "@mui/icons-material/Business";
import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";

import List from "@mui/material/List";
import { ArbeitsplanType, UserType, WorkPlan } from "../utils/types";
import "../App.css";
import styled from "styled-components";
import * as fs from "fs";
import _ from "lodash";
import ArbeitsplanPage from "./Arbeitsplan";
//#endregion
//#region interfaces

interface ArbeitsplanPageProps {
  mbNummer?: number;
}

interface WorkPlanProps {
  workPlan: ArbeitsplanType;
  users: UserType[];
}

interface WorkPlanListProps {
  kunde: string;
  workPlan: ArbeitsplanType[];
  users: UserType[];
}
//#endregion

const ArbeitsplanBasePage: React.FC<ArbeitsplanPageProps> = () => {
  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <ArbeitsplanPage />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default ArbeitsplanBasePage;
