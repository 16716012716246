import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import CourseListItem from "../components/CourseListItem";

import List from "@mui/material/List";

const CoursePage = () => {
  const [courses, setCourses] = useState([
    // {
    //   id: 1,
    //   name: "Course 1",
    //   maxParticipants: 10,
    //   enrolled: [1, 2, 3],
    //   date: "2021-10-01",
    //   additionalInfo: "This is a course about something",
    // },
    // {
    //   id: 2,
    //   name: "Course 2",
    //   maxParticipants: 20,
    //   enrolled: [4, 5, 6, 3],
    //   date: "2021-10-10",
    //   additionalInfo: "This is a course about something",
    // },
    // {
    //   id: 3,
    //   name: "Course 3",
    //   maxParticipants: 30,
    //   enrolled: [4, 7, 8],
    //   date: "2021-10-12",
    //   additionalInfo: "This is a course about something",
    // },
  ]);
  useEffect(() => {
    const getCourses = async () => {
      // fetch the threads from api endpoint
      const response = await fetch(`/api/course/`);

      // parse the data in json
      const data = await response.json();
      console.log("fetched courses", data);
      // update the state of threads
      setCourses(data);
    };
    getCourses();
  }, []);

  const postCourses = async (courseID, participants) => {
    const response = await fetch(`/api/setCourses/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        participants: participants,
        courseID: courseID,
      }),
    });
    const data = await response.json();
    console.log(data);
  };

  const enrollCourse = (id, userId) => {
    const updatedCourse = courses.find((course) => course.id === id);
    const newEnrolled = updatedCourse.enrolled + "," + String(userId);
    console.log("newEnrolled enroll", newEnrolled);
    setCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.id === id && userId !== ""
          ? {
              ...course,
              enrolled: newEnrolled,
            }
          : course
      )
    );
    postCourses(id, newEnrolled);
  };

  const deEnrollCourse = (id, userId) => {
    const updatedCourse = courses.find((course) => course.id === id);
    const newEnrolled = String(updatedCourse?.enrolled)
      .split(",")
      .filter(
        (enrolledUserId) =>
          enrolledUserId !== String(userId) && enrolledUserId !== ""
      )
      .join(",");
    console.log("newEnrolled deenroll", newEnrolled);
    setCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.id === id && userId !== ""
          ? {
              ...course,
              enrolled: newEnrolled,
            }
          : course
      )
    );
    postCourses(id, newEnrolled);
  };

  console.log("loading course");

  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="d-flex justify-content-between mb-3">
                <Typography variant="h5">Fortbildungen </Typography>
              </div>

              {/* <List Reactivate
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                aria-label="courses"
              >
                {courses &&
                  courses.map((course, index) => (
                    <CourseListItem
                      index={index}
                      key={index}
                      course={course}
                      enrollCourse={enrollCourse}
                      deEnrollCourse={deEnrollCourse}
                    />
                  ))}
              </List> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default CoursePage;
