import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Person2Icon from "@mui/icons-material/Person2";
import { ArbeitsplanType, AbwesenheitType, UserType } from "../utils/types";
import "../App.css";
import styled from "styled-components";
import { format } from "date-fns";
import Timeline from "react-calendar-timeline";
import {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline/lib";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import AuthContext from "../context/AuthContext";

const AbwesenheitPage = () => {
  const [arbeitsplan, setArbeitsplan] = useState<ArbeitsplanType[]>([]);
  const [users, setUsers] = useState([]);

  let { user } = useContext(AuthContext);

  useEffect(() => {
    const getArbeitsplan = async () => {
      const response = await fetch(`/api/getArbeitsplan/`);
      const data = await response.json();
      const abwesenheit = data.filter(
        (x: ArbeitsplanType) =>
          x.terminart == "Urlaub" || x.terminart == "Krank"
      );
      setArbeitsplan(abwesenheit);
    };
    getArbeitsplan();
  }, []);
  console.log("Arbeitsplan", arbeitsplan);

  useEffect(() => {
    const getAllUsers = async () => {
      const response = await fetch(`/api/getAllUsers/`);
      const data = await response.json();
      setUsers(data);
    };
    getAllUsers();
  }, []);

  // const derzUrlaube: UrlaubType[] = [
  //   {
  //     Person: "A. Hagemeister ",
  //     Zeit: "25.03. – 05.04.24",
  //   },
  //   {
  //     Person: "St. Wilmer ",
  //     Zeit: "25.03. – 05.04.24",
  //   },
  //   {
  //     Person: "C. Silva ",
  //     Zeit: "25.03. – 05.04.24",
  //   },
  //   {
  //     Person: "M. Herling ",
  //     Zeit: "02.04. – 03.04.24",
  //   },
  //   {
  //     Person: "Ch. Schumann ",
  //     Zeit: "02.04. – 05.04.24",
  //   },
  //   {
  //     Person: "D. Kutzner ",
  //     Zeit: "02.04. – 05.04.24",
  //   },
  //   {
  //     Person: "A. Drini ",
  //     Zeit: "02.04. – 05.04.24",
  //   },
  //   {
  //     Person: "G. Grothus",
  //     Zeit: "02.04. – 05.04.24",
  //   },
  //   {
  //     Person: "L. Arbeiter ",
  //     Zeit: "02.04. – 05.04.24",
  //   },
  //   {
  //     Person: "M. Niemeyer",
  //     Zeit: "05.04. – 08.04.24",
  //   },
  //   {
  //     Person: "Ivan Bechtoldt",
  //     Zeit: "05.04.24",
  //   },
  //   {
  //     Person: "D. Huster ",
  //     Zeit: "05.04.24",
  //   },
  //   {
  //     Person: "F. Oppermann",
  //     Zeit: "05.04. – 10.04.24",
  //   },
  // ];
  // const genUrlaube: UrlaubType[] = [
  //   {
  //     Person: "D. Schluch ",
  //     Zeit: "08.04.24",
  //   },
  //   {
  //     Person: "M. Diallo ",
  //     Zeit: "11.04. – 12.04.24",
  //   },
  //   {
  //     Person: "D. Körting",
  //     Zeit: "12.04.24",
  //   },
  //   {
  //     Person: "M. Greifelt",
  //     Zeit: "15.04.24",
  //   },
  //   {
  //     Person: "Igor Bechtoldt ",
  //     Zeit: "15.04. – 26.04.24",
  //   },
  //   {
  //     Person: "D. Walker ",
  //     Zeit: "17.04.24",
  //   },
  //   {
  //     Person: "D. Körting ",
  //     Zeit: "22.04.24",
  //   },
  //   {
  //     Person: "D. Huster ",
  //     Zeit: "22.04. – 26.04.24",
  //   },
  //   {
  //     Person: "N Weingarten ",
  //     Zeit: "22.04. – 30.04.24",
  //   },
  //   {
  //     Person: "D. Mönig ",
  //     Zeit: "23.04. – 26.04.24",
  //   },
  //   {
  //     Person: "K. Rohn ",
  //     Zeit: "26.04. – 17.05.24",
  //   },
  //   {
  //     Person: "T. Welp ",
  //     Zeit: "29.04. – 10.05.24",
  //   },
  //   {
  //     Person: "M. Greifelt ",
  //     Zeit: "06.05. – 17.05.24",
  //   },
  //   {
  //     Person: "H. Bosold ",
  //     Zeit: "06.05. – 11.05.24",
  //   },
  //   {
  //     Person: "L. Darmiento",
  //     Zeit: "17.05.24",
  //   },
  //   {
  //     Person: "L. Darmiento",
  //     Zeit: "21.05.24",
  //   },
  //   {
  //     Person: "St. Vitzthum ",
  //     Zeit: "03.06. – 07.06.24",
  //   },
  //   {
  //     Person: "H. Bosold ",
  //     Zeit: "03.06. – 21.06.24",
  //   },
  //   {
  //     Person: "L. Darmiento",
  //     Zeit: "05.06. – 10.06.24",
  //   },
  //   {
  //     Person: "D. Walker ",
  //     Zeit: "24.06. – 28.06.24",
  //   },
  //   {
  //     Person: "St. Laukemper ",
  //     Zeit: "26.08. – 11.09.24",
  //   },
  //   {
  //     Person: "H. Bosold",
  //     Zeit: "26.08. – 06.09.24",
  //   },
  // ];
  const groups = [];
  const items = [];

  // // Add derzeitige Urlaube to items
  // for (let i = 0; i < derzUrlaube.length; i++) {
  //   const personId = derzUrlaube[i].Person;
  //   if (!groups.some((group) => group.id === personId)) {
  //     groups.push({ id: personId, title: personId });
  //   }
  //   const dateList = derzUrlaube[i].Zeit.split(" – ");
  //   if (dateList.length === 1) {
  //     console.log(dateList[0]);
  //     const correctDate =
  //       dateList[0][-1] == "." ? dateList[0] + "24" : dateList[0];
  //     items.push({
  //       id: i,
  //       group: derzUrlaube[i].Person,
  //       start_time: moment(correctDate, "DD.MM.YY"),
  //       end_time: moment(correctDate, "DD.MM.YY").add(1, "day"),
  //     });
  //   } else if (dateList.length === 2) {
  //     const correctStart =
  //       dateList[0][-1] == "." ? dateList[0] + "24" : dateList[0];
  //     const correctEnd =
  //       dateList[1][-1] == "." ? dateList[1] + "24" : dateList[1];
  //     items.push({
  //       id: i,
  //       group: derzUrlaube[i].Person,
  //       start_time: moment(correctStart, "DD.MM.YY"),
  //       end_time: moment(correctEnd, "DD.MM.YY"),
  //     });
  //   }
  // }

  // // Add genehmigte Urlaube to items
  // for (let i = 0; i < genUrlaube.length; i++) {
  //   const personId = genUrlaube[i].Person;
  //   if (!groups.some((group) => group.id === personId)) {
  //     groups.push({ id: personId, title: personId });
  //   }
  //   const dateList = genUrlaube[i].Zeit.split(" – ");
  //   if (dateList.length === 1) {
  //     console.log(dateList[0]);
  //     const correctDate =
  //       dateList[0][-1] == "." ? dateList[0] + "24" : dateList[0];
  //     items.push({
  //       id: derzUrlaube.length + i,
  //       group: genUrlaube[i].Person,
  //       start_time: moment(correctDate, "DD.MM.YY"),
  //       end_time: moment(correctDate, "DD.MM.YY").add(1, "day"),
  //     });
  //   } else if (dateList.length === 2) {
  //     const correctStart =
  //       dateList[0][-1] == "." ? dateList[0] + "24" : dateList[0];
  //     const correctEnd =
  //       dateList[1][-1] == "." ? dateList[1] + "24" : dateList[1];
  //     items.push({
  //       id: derzUrlaube.length + i,
  //       group: genUrlaube[i].Person,
  //       start_time: moment(correctStart, "DD.MM.YY"),
  //       end_time: moment(correctEnd, "DD.MM.YY"),
  //     });
  //   }
  // }

  console.log("arbbb", arbeitsplan);
  for (let i = 0; i < arbeitsplan.length; i++) {
    const personId = arbeitsplan[i].mitarbeiter;

    const relatedUser = users.find(
      (x: UserType) => String(x.mitarbeiternummer) === personId.trim()
    );
    if (personId === "165") {
      console.log("relaUser", relatedUser);
    }
    if (!groups.some((group) => group.id === personId)) {
      groups.push({
        id: personId,
        title: relatedUser?.last_name + ", " + relatedUser?.first_name,
      });
    }

    items.push({
      id: arbeitsplan.length + i,
      group: arbeitsplan[i].mitarbeiter,
      start_time: moment(
        arbeitsplan[i].gesamttermindatumvon,
        "DD.MM.YYYY HH:mm"
      ).add(moment.duration(arbeitsplan[i].gesamtterminvon)),
      end_time: moment(
        arbeitsplan[i].gesamttermindatumbis,
        "DD.MM.YYYY HH:mm"
      ).add(moment.duration(arbeitsplan[i].gesamtterminbis)),
    });
  }
  console.log("lenn", groups);

  // Move current logged in user to the top of the list
  if (user) {
    const currentUserGroup = groups.find(
      (group) => group.id.toString() === user.mitarbeiternummer.toString()
    );
    if (currentUserGroup) {
      const currentUserIndex = groups.indexOf(currentUserGroup);
      groups.splice(currentUserIndex, 1);
      groups.unshift(currentUserGroup);
    }
  }

  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <div className="d-flex justify-content-between mb-3">
                <Typography variant="h4">Abwesenheit</Typography>
              </div>
              <div>
                <div>
                  <Timeline
                    className="calendarTimeline"
                    groups={groups}
                    items={items}
                    defaultTimeStart={moment().startOf("day")}
                    defaultTimeEnd={moment().startOf("day").add(1, "day")}
                    minZoom={(365.24 * 86400 * 1000) / 12 / 4} // how much can I zoom out (bigger=see less days)
                    maxZoom={(365.24 * 86400 * 1000) / 12} // how much can I zoom in (smaller=see less days)
                    // style={{ backgroundColor: "lightblue" }} // Set the background color of the timeline
                  >
                    <TimelineHeaders
                      className="sticky"
                      style={{ backgroundColor: "#e30713" }}
                    >
                      <DateHeader unit="primaryHeader" />
                      <DateHeader style={{ backgroundColor: "#e30713" }} />
                    </TimelineHeaders>
                  </Timeline>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default AbwesenheitPage;
