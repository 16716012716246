import React from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ResAppBar from "./ResAppBar";

const Header = () => {
  return (
    <div className="Header">
      <ResAppBar />
    </div>
  );
};

export default Header;
