import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { AuthProvider } from "./context/AuthContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Thread from "./pages/Thread";
import PrivateRoute from "./utils/PrivateRoute";

import Bookmark from "./pages/Bookmark";
import CoursePage from "./pages/Course";
import Forum from "./pages/Forum";
import Profile from "./pages/Profile";
import Topic from "./pages/Topic";
import ArbeitsplanBasePage from "./pages/ArbeitsplanBase";
import AbwesenheitPage from "./pages/Abwesenheit";

function App() {
  // <Route  element={<PrivateRoute />}>
  //         <Route path="/" element={<ThreadForm />} exact/>
  //         </Route>

  //  <Route path="/kummerkasten" element={<Kummerkasten />} exact/>
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Header />

          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />

            <Route element={<PrivateRoute />}>
              <Route path="/bookmark" element={<Bookmark />} />
              <Route path="/" element={<Home />} />
              <Route path="/threads/:id" element={<Thread />} />
              <Route path="/topic/:id" element={<Topic />} />
              <Route path="/profile/:id" element={<Profile />} />
              <Route path="/forum" element={<Forum />} />
              <Route path="/course" element={<CoursePage />} />
              <Route path="/arbeitsplan" element={<ArbeitsplanBasePage />} />
              <Route path="/abwesenheit" element={<AbwesenheitPage />} />
            </Route>
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
