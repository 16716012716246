//#region imports
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Person2Icon from "@mui/icons-material/Person2";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import BusinessIcon from "@mui/icons-material/Business";
import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";

import List from "@mui/material/List";
import { ArbeitsplanType, UserType, WorkPlan } from "../utils/types";
import "../App.css";
import styled from "styled-components";
import * as fs from "fs";
import csvParser from "csv-parser";
import _ from "lodash";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal/Modal";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//#endregion
//#region interfaces

interface ArbeitsplanPageProps {
  mbNummer?: number;
  showArbeitsplane?: boolean;
}

interface WorkPlanProps {
  workPlan: ArbeitsplanType;
  users: UserType[];
}

interface WorkPlanListProps {
  kunde: string;
  workPlan: ArbeitsplanType[];
  users: UserType[];
}
//#endregion

const ArbeitsplanPage: React.FC<ArbeitsplanPageProps> = ({
  mbNummer,
  showArbeitsplane,
}) => {
  //#region Initializations
  const [arbeitsplan, setArbeitsplan] = useState<ArbeitsplanType[]>([]);
  const [bauArbeitsplan, setBauArbeitsplan] = useState([]);
  const [users, setUsers] = useState([]);
  const [pvAbteilung, setPVAbteilung] = useState([]);
  const [wartung, setWartung] = useState([]);

  useEffect(() => {
    const getArbeitsplan = async () => {
      const response = await fetch(`/api/getArbeitsplan/`);
      const data = await response.json();
      console.log("mbNummer", mbNummer);
      const filteredData =
        mbNummer != null && mbNummer > 0
          ? data.filter((x: ArbeitsplanType) =>
              x.mitarbeiter.includes(mbNummer.toString())
            )
          : data;
      console.log("filteredData", filteredData);
      setArbeitsplan(filteredData);

      const bauArbeitsplan = _.groupBy(
        filteredData.filter((x) => x.terminart === "Bau (tägl. Fahrten)"),
        "kurzname_kunde"
      );
      const bauArbeitsplanList = Object.entries(bauArbeitsplan).map(
        ([kunde, arbeitsplan]) => ({
          kunde,
          arbeitsplan,
        })
      );
      setBauArbeitsplan(bauArbeitsplanList);

      const pv = _.groupBy(
        filteredData.filter((x) => x.terminart === "PV-Abteilung"),
        "kurzname_kunde"
      );
      const pvList = Object.entries(pv).map(([kunde, arbeitsplan]) => ({
        kunde,
        arbeitsplan,
      }));
      setPVAbteilung(pvList);

      const wart = _.groupBy(
        filteredData.filter((x) => x.terminart === "Wartung"),
        "kurzname_objekt"
      );
      const wartList = Object.entries(wart).map(([kunde, arbeitsplan]) => ({
        kunde,
        arbeitsplan,
      }));
      setWartung(wartList);
    };

    getArbeitsplan();
  }, []);

  useEffect(() => {
    const getAllUsers = async () => {
      const response = await fetch(`/api/getAllUsers/`);
      const data = await response.json();
      setUsers(data);
    };
    getAllUsers();
  }, []);
  //#endregion

  // #region ExpandCollapse
  const [isExpandedWartung, setIsExpandedWartung] = useState(
    showArbeitsplane ?? false
  );
  const toggleExpandWartung = () => {
    setIsExpandedWartung(!isExpandedWartung);
  };
  const [isExpandedPV, setIsExpandedPV] = useState(false);
  const toggleExpandPV = () => {
    setIsExpandedPV(!isExpandedPV);
  };
  const [isExpandedKundendienst, setIsExpandedKundendienst] = useState(
    showArbeitsplane ?? false
  );
  const toggleExpandKundendienst = () => {
    setIsExpandedKundendienst(!isExpandedKundendienst);
  };
  const [isExpandedBaustellentermin, setIsExpandedBaustellentermin] = useState(
    showArbeitsplane ?? false
  );
  const toggleExpandBaustellentermin = () => {
    setIsExpandedBaustellentermin(!isExpandedBaustellentermin);
  };
  const [isExpandedBau, setIsExpandedBau] = useState(showArbeitsplane ?? false);
  const toggleExpandBau = () => {
    setIsExpandedBau(!isExpandedBau);
  };
  // #endregion

  // useEffect(() => {
  //   const getUser = async () => {
  //     const response = await fetch(`/api/getUser/232`);
  //     const data = await response.json();
  //     console.log("einUser", data);
  //     return data;
  //   };
  //   getUser();
  // }, []);

  console.log("Arbeitsplan", arbeitsplan);
  console.log(
    "baustelle",
    arbeitsplan.filter((x) => x.terminart === "Baustellentermin")
  );

  return (
    <div>
      {mbNummer == null && (
        <>
          <div className="d-flex justify-content-between mb-3">
            <Typography variant="h4">Arbeitspläne</Typography>
          </div>
          <hr
            style={{
              width: "100%",
              height: "3px",
              backgroundColor: "black",
              margin: "5px 0",
            }}
          />
        </>
      )}
      {bauArbeitsplan != null && bauArbeitsplan.length > 0 && (
        <>
          <div
            className="d-flex justify-content-between mb-3"
            onClick={toggleExpandBau}
          >
            <Typography variant="h5">
              Bau (Tägl. Fahrten)
              {isExpandedBau ? (
                <ExpandLessIcon style={{ marginLeft: "5px" }} />
              ) : (
                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
              )}
            </Typography>
          </div>
          {isExpandedBau && (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {bauArbeitsplan.map(({ kunde, arbeitsplan }, index) => (
                  <GeneralArbeitsplanListItem
                    key={index}
                    workPlan={arbeitsplan}
                    kunde={kunde}
                    users={users}
                  />
                ))}
              </div>
            </List>
          )}
        </>
      )}

      {
        // TODO: Maybe set this plan once so the filter has not to be done every time
      }
      {arbeitsplan.filter((x) => x.terminart === "Baustellentermin") != null &&
        arbeitsplan.filter((x) => x.terminart === "Baustellentermin").length >
          0 && (
          <>
            <div
              className="d-flex justify-content-between mb-3"
              onClick={toggleExpandBaustellentermin}
            >
              {" "}
              <Typography variant="h5">
                Baustellentermine
                {isExpandedBaustellentermin ? (
                  <ExpandLessIcon style={{ marginLeft: "5px" }} />
                ) : (
                  <ExpandMoreIcon style={{ marginLeft: "5px" }} />
                )}
              </Typography>
            </div>
            {isExpandedBaustellentermin && (
              <div className="th</button>read-list-item">
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {arbeitsplan
                      .filter((x) => x.terminart === "Baustellentermin")
                      .map((arbeitsplan, index) => (
                        <BaustellenTerminListItem
                          key={index}
                          workPlan={arbeitsplan}
                          users={users}
                        />
                      ))}
                  </div>
                </List>
              </div>
            )}
          </>
        )}

      {arbeitsplan.filter((x) => x.terminart.startsWith("Kundendienst")) !=
        null &&
        arbeitsplan.filter((x) => x.terminart.startsWith("Kundendienst"))
          .length > 0 && (
          <>
            <div
              className="d-flex justify-content-between mb-3"
              onClick={toggleExpandKundendienst}
            >
              <Typography variant="h5">
                Kundendienst
                {isExpandedKundendienst ? (
                  <ExpandLessIcon style={{ marginLeft: "5px" }} />
                ) : (
                  <ExpandMoreIcon style={{ marginLeft: "5px" }} />
                )}
              </Typography>
            </div>
            {isExpandedKundendienst && (
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {arbeitsplan
                    .filter((x) => x.terminart.startsWith("Kundendienst"))
                    .map((arbeitsplan, index) => (
                      <KundendienstListItem
                        key={index}
                        workPlan={arbeitsplan}
                        users={users}
                      />
                    ))}
                </div>
              </List>
            )}
          </>
        )}

      {pvAbteilung != null && pvAbteilung.length > 0 && (
        <>
          <div
            className="d-flex justify-content-between mb-3"
            onClick={toggleExpandPV}
          >
            <Typography variant="h5">
              PV-Abteilung
              {isExpandedPV ? (
                <ExpandLessIcon style={{ marginLeft: "5px" }} />
              ) : (
                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
              )}
            </Typography>
          </div>
          {isExpandedPV && (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {pvAbteilung.map(({ kunde, arbeitsplan }, index) => (
                  <GeneralArbeitsplanListItem
                    key={index}
                    workPlan={arbeitsplan}
                    kunde={kunde}
                    users={users}
                  />
                ))}
              </div>
            </List>
          )}
        </>
      )}
      {wartung != null && wartung.length > 0 && (
        <>
          <div
            className="d-flex justify-content-between mb-3"
            onClick={toggleExpandWartung}
          >
            <Typography variant="h5">
              Wartung
              {isExpandedWartung ? (
                <ExpandLessIcon style={{ marginLeft: "5px" }} />
              ) : (
                <ExpandMoreIcon style={{ marginLeft: "5px" }} />
              )}
            </Typography>
          </div>
          {isExpandedWartung && (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {wartung.map(({ kunde, arbeitsplan }, index) => (
                  <GeneralArbeitsplanListItem
                    key={index}
                    workPlan={arbeitsplan}
                    kunde={kunde}
                    users={users}
                  />
                ))}
              </div>
            </List>
          )}
        </>
      )}
    </div>
  );
};

//#region WorkPlanComponentList
const GeneralArbeitsplanListItem: React.FC<WorkPlanListProps> = ({
  kunde,
  workPlan,
  users,
}) => {
  console.log("workplan", workPlan);
  const mitarbeiter = [
    ...new Set(
      workPlan.flatMap((plan) =>
        plan.mitarbeiter.split(",").map((x) => x?.trim())
      )
    ),
  ];
  const userList = users.filter((user) =>
    mitarbeiter.includes(String(user.mitarbeiternummer))
  );

  console.log("mitarbeiternummer", mitarbeiter);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log("clicked");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const earliestDate = _.minBy(workPlan, (plan) => {
    const date = moment(plan.gesamttermindatumvon, "DD.MM.YYYY HH:mm");
    const date2 = moment(plan.datum, "DD.MM.YYYY HH:mm");
    return Math.min(date.unix(), date2.unix());
  })?.gesamttermindatumvon;

  const latestDate = _.maxBy(workPlan, (plan) => {
    const date = moment(plan.gesamttermindatumvon, "DD.MM.YYYY HH:mm");
    const date2 = moment(plan.datum, "DD.MM.YYYY HH:mm");
    return Math.max(date.unix(), date2.unix());
  })?.gesamttermindatumbis;

  return (
    <>
      <ListItem
        alignItems="flex-start"
        className="thread-list"
        style={{ width: "50%" }}
      >
        <WorkPlanContainer
          style={{
            margin: "0px",
            marginRight: "0px",
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "100%",
          }}
        >
          <div onClick={openModal}>
            <Title
              className="ArbeitsplanTitle"
              style={{ wordBreak: "break-word" }}
            >
              {kunde}
            </Title>
            <hr
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
                margin: "10px 0",
              }}
            />
            <Info>
              <div>
                <KalenderIcon />
                {earliestDate.replace(" 00:00", "")}{" "}
                {latestDate && ` - ${latestDate.replace(" 00:00", "")}`}
              </div>
              <Monteur userList={userList} />
            </Info>
          </div>
        </WorkPlanContainer>
      </ListItem>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GeneralArbeitsplanDetails
          workPlan={workPlan}
          kunde={kunde}
          users={users}
        />
      </Modal>
    </>
  );
};
//#endregion

//#region GeneralArbeitsplanDetails
const GeneralArbeitsplanDetails: React.FC<WorkPlanListProps> = ({
  kunde,
  workPlan: workPlanList,
  users: users,
}) => {
  return (
    <>
      <div
        className="thread-list-item"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          width: "90%",
          marginLeft: "5%",
          marginTop: "20vh",
          overflow: "auto",
        }}
      >
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            overflow: "auto",
            maxHeight: "600px",
          }}
        >
          <div
            className="d-flex justify-content-between mb-3"
            style={{ marginLeft: "15px", marginTop: "10px" }}
          >
            <Typography variant="h4">Vorgänge</Typography>
          </div>
          {workPlanList.map((workPlan, index) => (
            <GeneralArbeitsplanDetailsItem
              key={index}
              workPlan={workPlan}
              users={users}
            />
          ))}
        </List>
      </div>
    </>
  );
};
//#endregion

//#region GeneralArbeitsplanDetailsItem
const GeneralArbeitsplanDetailsItem: React.FC<WorkPlanProps> = ({
  workPlan,
  users,
}) => {
  const mitarbeiter = [
    ...new Set(workPlan.mitarbeiter.split(",").map((x) => x?.trim())),
  ];
  console.log("mitarbeiter", mitarbeiter);
  const userList = users.filter((user) =>
    mitarbeiter.includes(String(user.mitarbeiternummer))
  );
  const bauleiter = users.find(
    (x) => String(x.mitarbeiternummer) === workPlan.bauleiter?.trim()
  );
  return (
    <WorkPlanContainer>
      <Title className="ArbeitsplanTitle" style={{ wordBreak: "break-word" }}>
        {workPlan.vorgang}
      </Title>
      <hr
        style={{
          width: "100%",
          height: "2px",
          backgroundColor: "black",
          margin: "10px 0",
        }}
      />
      <Info>
        <div>
          <KalenderIcon />
          {workPlan.datum.replace(" 00:00", "")}{" "}
          {workPlan.gesamttermindatumbis &&
            ` - ${workPlan.gesamttermindatumbis.replace(" 00:00", "")}`}
        </div>
        <div>
          <AbfahrtIcon />
          {workPlan.zeitvon.replace("0:00", "0")} -
          {workPlan.zeitbis.replace("0:00", "0")}
        </div>
        <Monteur userList={userList} />
      </Info>

      <p>
        {workPlan.bemerkung.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
      <AdresseObjektBauleiter workPlan={workPlan} users={users} />
    </WorkPlanContainer>
  );
};
//#endregion

//#region BaustellenTerminListItem
const BaustellenTerminListItem: React.FC<WorkPlanProps> = ({
  workPlan,
  users,
}) => {
  const mitarbeiter = [
    ...new Set(workPlan.mitarbeiter.split(",").map((x) => x?.trim())),
  ];
  const userList = users.filter((user) =>
    mitarbeiter.includes(String(user.mitarbeiternummer))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log("clicked");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ListItem
      alignItems="flex-start"
      className="thread-list"
      style={{ width: "50%" }}
    >
      <WorkPlanContainer
        style={{
          margin: "0px",
          marginRight: "0px",
          backgroundColor: "#f5f5f5",
          width: "100%",
          height: "100%",
        }}
      >
        <div onClick={openModal}>
          <Title
            className="ArbeitsplanTitle"
            style={{ wordBreak: "break-word" }}
          >
            {workPlan.bemerkung}
          </Title>
          <hr
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "black",
              margin: "10px 0",
            }}
          />
          <Info>
            <div>
              <KalenderIcon />
              {workPlan.datum.replace(" 00:00", "")}
            </div>
            <div>
              <AbfahrtIcon />
              {workPlan.zeitvon.replace("0:00", "0")} -
              {workPlan.zeitbis.replace("0:00", "0")}
            </div>
            <Monteur userList={userList} />
          </Info>
        </div>
      </WorkPlanContainer>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GeneralArbeitsplanDetails
          workPlan={[workPlan]}
          kunde={workPlan.kurzname_kunde}
          users={users}
        />
      </Modal>
    </ListItem>
  );
};
//#endregion

//#region KundendienstListItem
const KundendienstListItem: React.FC<WorkPlanProps> = ({ workPlan, users }) => {
  const mitarbeiter = [
    ...new Set(workPlan.mitarbeiter.split(",").map((x) => x?.trim())),
  ];
  const userList = users.filter((user) =>
    mitarbeiter.includes(String(user.mitarbeiternummer))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log("clicked");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <ListItem
        alignItems="flex-start"
        className="thread-list"
        style={{ width: "50%" }}
      >
        <WorkPlanContainer
          style={{
            margin: "0px",
            marginRight: "0px",
            backgroundColor: "#f5f5f5",
            width: "100%",
            height: "100%",
          }}
        >
          <div onClick={openModal}>
            <Title
              className="ArbeitsplanTitle"
              style={{ wordBreak: "break-word" }}
            >
              {workPlan.kurzname_kunde}
            </Title>
            <hr
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
                margin: "10px 0",
              }}
            />
            <Info>
              <div>
                <KalenderIcon />
                {workPlan.datum.replace(" 00:00", "")}{" "}
                {workPlan.gesamttermindatumbis &&
                  ` - ${workPlan.gesamttermindatumbis.replace(" 00:00", "")}`}
              </div>
              <div>
                <AbfahrtIcon />
                {workPlan.zeitvon.replace("0:00", "0")} -
                {workPlan.zeitbis.replace("0:00", "0")}
              </div>
              <Monteur userList={userList} />
            </Info>
          </div>
        </WorkPlanContainer>
      </ListItem>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <GeneralArbeitsplanDetails
          workPlan={[workPlan]}
          kunde={workPlan.kurzname_kunde}
          users={users}
        />
      </Modal>
    </>
  );
};
//#endregion

//#region Objekte
const AdresseObjektBauleiter = ({ workPlan, users }) => {
  const bauleiter = users.find(
    (x) => String(x.mitarbeiternummer) === workPlan.bauleiter?.trim()
  );
  return (
    <Grid container spacing={2}>
      {workPlan.objekt && (
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <ObjektIcon />
            <Typography variant="h6">Objekt</Typography>
          </Box>
          <Typography>{workPlan.objekt}</Typography>
          <Typography>{workPlan.kurzname_objekt}</Typography>
          {workPlan.tel_objekt && (
            <Typography>{workPlan.tel_objekt}</Typography>
          )}
          {workPlan.mobil_objekt && (
            <Typography>{workPlan.mobil_objekt}</Typography>
          )}
        </Grid>
      )}
      {workPlan.adresse && (
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <AdresseIcon />
            <Typography variant="h6">Adresse</Typography>
          </Box>
          <Typography>{workPlan.adresse}</Typography>
          {workPlan.tel_adresse && (
            <Typography>{workPlan.tel_adresse}</Typography>
          )}
          {workPlan.mobil_adresse && (
            <Typography>{workPlan.mobil_adresse}</Typography>
          )}
        </Grid>
      )}
      {bauleiter && (
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <PersonIcon />
            <Typography variant="h6">Bauleiter</Typography>
          </Box>
          {bauleiter.last_name && bauleiter.first_name && (
            <Typography>
              {bauleiter.last_name}, {bauleiter.first_name}
            </Typography>
          )}
          {workPlan.tel_bauleiter && (
            <Typography>{workPlan.tel_bauleiter}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const Monteur = ({ userList }) => {
  return (
    <div className="monteurList">
      <MonteurIcon />
      {userList.map(
        (monteur, index) =>
          (monteur.first_name != null || monteur.last_name != null) &&
          (monteur.first_name != "" || monteur.last_name != "") && (
            <strong key={index}>
              {monteur.last_name}, {monteur.first_name[0]}.
              &nbsp;&nbsp;&nbsp;&nbsp;
            </strong>
          )
      )}
    </div>
  );
};
//#endregion

//#region css
const WorkPlanContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const Info = styled.div`
  margin-bottom: 15px;
`;

const InfoItem = styled.p`
  margin: 0;
  font-weight: normal;
`;

const MonteureList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

const MonteureItem = styled.li`
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 5px;
  background-color: #f2f2f2;
  border-radius: 1px;
  font-size: 1em;
`;

const AbfahrtIcon = styled(AccessTimeIcon)`
  margin-right: 5px;
`;

const KalenderIcon = styled(CalendarIcon)`
  margin-right: 5px;
`;

const HelpIcon = styled(HelpOutlineIcon)`
  margin-right: 5px;
`;

const PersonIcon = styled(Person2Icon)`
  margin-right: 5px;
`;
const AdresseIcon = styled(BusinessIcon)`
  margin-right: 5px;
`;
const ObjektIcon = styled(ConstructionIcon)`
  margin-right: 5px;
`;
const MonteurIcon = styled(EngineeringIcon)`
  margin-right: 5px;
`;
const TelefonIcon = styled(PhoneIcon)`
  margin-right: 5px;
`;
//#endregion

export default ArbeitsplanPage;
