import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ThreadListItem from "../components/ThreadListItem";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ThreadForm from "../components/ThreadForm";
import Beschwerden from "../components/Beschwerden";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import AuthContext from "../context/AuthContext";
import MessageIcon from "@mui/icons-material/Message";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import BookmarksIcon from "@mui/icons-material/Bookmarks";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import KKasten from "../components/KKasten";
import { forumTopics } from "../utils/optionSets";
import { UserType } from "../utils/types";
import ArbeitsplanPage from "./Arbeitsplan";

const ThreadListPage = () => {
  // initalize component state
  const [threads, setThreads] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [topThreads, setTopThreads] = useState([]);

  useEffect(() => {
    const getTopThreads = async () => {
      // fetch top threads
      const response = await fetch(`api/topThreads`);
      const data = await response.json();
      setTopThreads(data);
    };
    getTopThreads();
  }, []);

  useEffect(() => {
    const getThreads = async () => {
      // fetch the threads from api endpoint
      const response = await fetch(`/api/threads/?page=1`);

      // parse the data in json
      const data = await response.json();

      // update the state of threads
      setThreads(data.results);

      // check if there is more threads
      if (data.next === null) {
        setHasMore(false);
      }
    };
    getThreads();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // fetch next page threads
  const getMoreThreads = async () => {
    // fetch the threads from api endpoint
    const response = await fetch(`/api/threads/?page=${page}`);
    // parse the data in json
    const data = await response.json();

    console.log("fetching");

    return data.results;
  };

  const fetchData = async () => {
    // get more threads from next fetch
    const moreThreads = await getMoreThreads();

    // update the thread state by combining data
    setThreads([...threads, ...moreThreads]);

    // check the fetch of last page, if yes, HasMore is false
    if (moreThreads.length === 0 || moreThreads.length < 4) {
      setHasMore(false);
    }
    setPage(page + 1);
  };

  // style the paper component
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  let { user } = useContext(AuthContext);
  let currentUser: UserType = user;
  const userRole = user != null ? currentUser.user_id : null;
  const mbKennung = user != null ? currentUser.mitarbeiternummer : null;
  console.log("userRole", user);
  console.log("mbKennung", mbKennung);

  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            {/* <Grid
              item
              md={2}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <div className="d-flex mb-3">
                <Typography variant="h5">Topics </Typography>
              </div>

              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                aria-label="topics"
              >
                {Object.keys(forumTopics).map((key, index) => (
                  <div key={index}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "black",
                        textTransform: "capitalize",
                      }}
                      to={`/topic/${key}`}
                    >
                      <ListItem key={index}>
                        <ListItemButton>
                          <ListItemText
                            primary={
                              <Typography>{forumTopics[key]}</Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  </div>
                ))}
              </List>
            </Grid> */}
            <Grid item xs={12} md={6}>
              <div className="stempeln">
                <Typography variant="h5">Stempeluhr</Typography>
                <p>Eingestempelt seit: 7 Stunden, 36 Minuten</p>
                <button id="stempelButton">
                  <p id="btnText">Ausstempeln</p>
                  <div className="check-box">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                      <path
                        fill="transparent"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="kummerkasten">
                <Typography variant="h5">Ideenbox</Typography>
                {/* {userRole !== 8 && <KKasten />} Reactivate
                {userRole === 8 && <Beschwerden />} */}
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="arbeitsplane">
                <Typography variant="h5">Deine Arbeitspläne</Typography>
                <hr
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "black",
                    margin: "10px 0",
                  }}
                />
                <ArbeitsplanPage mbNummer={mbKennung} showArbeitsplane={true} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}></Grid>

            <Grid item xs={12} md={6}>
              <div className="d-flex justify-content-between mb-3">
                <Typography variant="h5">Neuste Nachrichten</Typography>
                <ThreadForm />
              </div>
              {/* <div> Reactivate
                {threads.map((thread, index) => (
                  <ThreadListItem key={index} thread={thread} />
                ))}
                {hasMore && (
                  <ListItemButton
                    onClick={fetchData}
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    Load more threads...
                  </ListItemButton>
                )}
                {!hasMore && (
                  <ListItem>
                    <p style={{ textAlign: "center", marginTop: 20 }}>
                      <span>You have seen all the threads.</span>
                    </p>
                  </ListItem>
                )}
              </div> */}
            </Grid>

            <Grid
              item
              md={4}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <div className="d-flex mb-3">
                <Typography variant="h5">
                  <BookmarksIcon sx={{ marginRight: 2 }} />
                  Beliebte Diskussionen{" "}
                </Typography>
              </div>
              {/* {topThreads.map((thread, index) => ( Reactivate
                <Box sx={{ minWidth: 275, marginTop: 2 }} key={index}>
                  <Link
                    to={`/threads/${thread.id}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card className="hotTopics" variant="outlined" key={index}>
                      <React.Fragment>
                        <CardContent>
                          <Typography variant="h6" component="div">
                            {thread?.subject}
                          </Typography>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {thread?.creator}
                          </Typography>
                          <Typography variant="body2" color="text.primary">
                            <MessageIcon sx={{ marginRight: 1 }} />
                            {thread?.replyCount}
                          </Typography>
                        </CardContent>
                      </React.Fragment>
                    </Card>
                  </Link>
                </Box>
              ))} */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default ThreadListPage;
