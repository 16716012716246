import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ThreadForm from "../components/ThreadForm";
import ThreadListItem from "../components/ThreadListItem";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { forumTopics } from "../utils/optionSets";

const ThreadListPage = () => {
  // initalize component state
  const [threads, setThreads] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [topThreads, setTopThreads] = useState([]);

  useEffect(() => {
    const getTopThreads = async () => {
      // fetch top threads
      const response = await fetch(`api/topThreads`);
      const data = await response.json();
      setTopThreads(data);
    };
    getTopThreads();
  }, []);

  console.log("top threads", topThreads);

  useEffect(() => {
    const getThreads = async () => {
      // fetch the threads from api endpoint
      const response = await fetch(`/api/threads/?page=1`);

      // parse the data in json
      const data = await response.json();

      // update the state of threads
      setThreads(data.results);

      // check if there is more threads
      if (data.next === null) {
        setHasMore(false);
      }
    };
    getThreads();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // fetch next page threads
  const getMoreThreads = async () => {
    // fetch the threads from api endpoint
    const response = await fetch(`/api/threads/?page=${page}`);
    // parse the data in json
    const data = await response.json();

    console.log("fetching");

    return data.results;
  };

  const fetchData = async () => {
    // get more threads from next fetch
    const moreThreads = await getMoreThreads();

    // update the thread state by combining data
    setThreads([...threads, ...moreThreads]);

    // check the fetch of last page, if yes, HasMore is false
    if (moreThreads.length === 0 || moreThreads.length < 4) {
      setHasMore(false);
    }
    setPage(page + 1);
  };

  // style the paper component
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));
  return <></>;
  //Reactivate:

  return (
    <div style={{ marginTop: 100 }}>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="d-flex justify-content-between mb-3">
                <Typography variant="h5">Themen </Typography>
              </div>

              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                aria-label="topics"
              >
                {Object.keys(forumTopics).map((key, index) => (
                  <div key={index}>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "black",
                        textTransform: "capitalize",
                      }}
                      to={`/topic/${key}`}
                    >
                      <ListItem key={index}>
                        <ListItemButton>
                          <ListItemText
                            primary={
                              <Typography>{forumTopics[key]}</Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  </div>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="d-flex justify-content-between mb-3">
                <Typography variant="h5">Neuste Nachrichten</Typography>
                <ThreadForm />
              </div>
              <div>
                {threads.map((thread, index) => (
                  <ThreadListItem key={index} thread={thread} />
                ))}
                {hasMore && (
                  <ListItemButton
                    onClick={fetchData}
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    Load more threads
                  </ListItemButton>
                )}
                {!hasMore && (
                  <ListItem>
                    <p style={{ textAlign: "center", marginTop: 20 }}>
                      <span>You have seen all the threads.</span>
                    </p>
                  </ListItem>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default ThreadListPage;
