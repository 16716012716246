import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import AuthContext from "../context/AuthContext";
import { ProfileType, UserType } from "../utils/types";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ResAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let { user } = useContext(AuthContext); // Initialize user variable with the correct type
  console.log("abz", user);

  let { logoutUser } = useContext(AuthContext);

  // initalize thread and posts component state
  const [profile, setProfile] = useState<ProfileType>();

  useEffect(() => {
    const getProfile = async () => {
      if (user !== null) {
        let userID = user["user_id"];
        const response = await fetch(`/api/profile/${userID}`);

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      }
    };
    getProfile();
  }, [user]);

  return (
    <AppBar position="fixed" style={{ background: "#1d9538" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* <img src={icon} alt="Icon" style={{ height: '40px', marginRight: '2rem' }} /> */}

          {/* Profile in AppBar*/}
          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            {user && (
              <div className="d-flex justify-content-end">
                {/* <Typography
                  noWrap
                  component="a"
                  onClick={handleOpenUserMenu}
                  // href={`/profile/${(user as UserType).user_id}`}
                  sx={{
                    mr: 1,
                    p: 2,
                    display: "flex",
                    fontFamily: "monospace",
                    fontWeight: 500,
                    letterSpacing: "12",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {(user as UserType).username}
                </Typography> */}

                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleOpenUserMenu}
                >
                  <AccountCircleIcon style={{ fontSize: "120%" }} />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  sx={{
                    display: "block",
                  }}
                >
                  <MenuItem
                    key="profile"
                    component={Link}
                    to={`/profile/${user["user_id"]}`}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">Profil</Typography>
                  </MenuItem>
                  <MenuItem
                    key="bookmark"
                    component={Link}
                    to="/bookmark"
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">Lesezeichen</Typography>
                  </MenuItem>
                  <MenuItem key="logout" onClick={logoutUser as any}>
                    <Typography textAlign="center">Abmelden</Typography>
                  </MenuItem>
                </Menu>
              </div>
            )}
            {!user && (
              <div className="d-flex justify-content-end">
                <Typography
                  noWrap
                  component="a"
                  href="/login"
                  sx={{
                    mr: 2,
                    display: "flex",
                    fontFamily: "monospace",
                    fontWeight: 500,
                    letterSpacing: "12",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  Anmelden
                </Typography>
              </div>
            )}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
              alignItems: "center",
            }}
          ></Box>

          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              style={{ height: "100%", scale: "80%" }}
            />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: "block",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  textTransform: "capitalize",
                }}
                to={`/`}
              >
                <MenuItem key="Home" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
              </Link>

              {/* {Object.keys(options).map((key, index) => (
                [
                <Link style={{textDecoration:"none", color:'black', textTransform: 'capitalize'}} 
                to={`/topic/${key}`}>*/}
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  textTransform: "capitalize",
                }}
                to={`/forum`}
              >
                <MenuItem key="forum" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Forum</Typography>
                </MenuItem>
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  textTransform: "capitalize",
                }}
                to={`/course`}
              >
                <MenuItem key="course" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Fortbildungen</Typography>
                </MenuItem>
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  textTransform: "capitalize",
                }}
                to={`/arbeitsplan`}
              >
                <MenuItem key="arbeitsplan" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Arbeitsplan</Typography>
                </MenuItem>
              </Link>

              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  textTransform: "capitalize",
                }}
                to={`/abwesenheit`}
              >
                <MenuItem key="abwesenheit" onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Abwesenheit</Typography>
                </MenuItem>
              </Link>

              {user && user.is_staff && (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                  to={`/signup`}
                >
                  <MenuItem key="signup" onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">User anlegen</Typography>
                  </MenuItem>
                </Link>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResAppBar;
